/** @format */

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$clr-primary: #0063f7;
$clr-secondary: #001431;
$clr-light: #f4f6f9;
$clr-silver: #6d7580;
a {
  text-decoration: none !important;
}

* {
  font-family: "Roboto", sans-serif;
}
.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none !important;
}

.navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px !important;
  position: absolute !important;
  height: 2px !important;
  background-color: #000 !important;
  top: 0 !important;
  left: 0 !important;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after {
  top: 8px !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

body {
  user-select: none;
  background-color: $clr-light;
}
::-webkit-scrollbar {
  height: 5px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: $clr-silver;
  border-radius: 50px;
}

// mixins
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// *************************** components ***************************
.header {
  // background-color: $clr-secondary;
  // padding: 0.5rem 0;
  // background-color: #001b44dc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.148);

  .logo-text {
    font-size: 28px;
    font-weight: 700;
  }
  .nav-link {
    color: #6d7580;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    &.active {
      color: $clr-primary;
    }
  }

  // .navbar-toggler {
  //   color: white;
  // }
}
.slider-col {
  margin-top: 40px;
}
.vertical-card {
  display: flex;
  background-color: white;
  border-radius: 6px 0px 0px 6px;

  .heading {
    color: #545d69;
    font-size: 18px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 800px) {
  .vertical-card {
    &.responsive-card {
      flex-direction: column;
      img {
        width: 100%;
      }
    }
  }
  .slider-col {
    margin-top: 0px !important;
  }
}
.nav-btn {
  background: linear-gradient(
    90.22deg,
    #18c8df -5.2%,
    #f805fd 117.97%
  ) !important;
}
.view-all {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}

.advertise-banner {
  min-height: 400px;
  color: white;
  background: linear-gradient(
      360deg,
      #130f26 10.07%,
      rgba(19, 15, 38, 0) 106.71%
    ),
    url("./media/adbg.png") no-repeat center;
  background-size: cover;
  padding: 40px 10px;
  border-radius: 6px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .heading {
    font-size: 38px;
    font-weight: 700;
  }

  button {
    all: unset;
    color: inherit;
    background: rgba(123, 97, 255, 0.5);
    border-radius: 4px;
    padding: 10px 24px;
    text-transform: uppercase;
    cursor: pointer;
  }
}
.btn-read {
  // background: transparent;
  // border: 1px solid red !important;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 600;
  background: linear-gradient(90deg, #f805fd 0%, #18c8df 100%);
  transition: 1s;
  &:hover {
    // background: transparent;
  }
}
.banner {
  background: url("./media/banner.png") no-repeat center;
  background-size: cover;
  padding: 50px 20px;
  color: #fff;

  .content {
    max-width: 500px;
    .red-badge {
      background: #ff6262;
      border-radius: 100px;
      padding: 10px 24px;
      display: inline-block;
    }
  }
}

.trending-topics {
  color: white;
  font-size: 18px;
  .grid-content {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;

    .bg-card {
      min-height: 350px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      padding: 10px;
      border-radius: 6px;

      .primary-badge {
        border-radius: 100px;
        display: inline-block;
        padding: 4px 24px;
        background-color: rgba(0, 0, 0, 0.75);
        span {
          background: linear-gradient(90.22deg, #0063f7 -5.2%, #b0a9fc 117.97%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .content {
        position: absolute;
        bottom: 20px;
        .heading {
          font-size: 18px;

          &.md-heading {
            font-size: 24px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .md-heading {
      font-size: 18px !important;
    }
    .grid-content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 600px) {
    .md-heading {
      font-size: 18px !important;
    }
    .grid-content {
      grid-template-columns: 1fr;
    }
  }
}

.horizontol-card {
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  overflow: hidden;

  .heading {
    font-size: 18px;
  }
  .wrapper {
    height: 250px;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.footer {
  background-color: #001431;
  color: white;
  a {
    font-family: "roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #eaecf0;
    text-decoration: none;
  }
  h4 {
    font-family: "roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #98a2b3;
  }
  .logo {
    background: linear-gradient(90.22deg, #0063f7 -5.2%, #b0a9fc 117.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
    font-size: 28px;
  }

  .bottom-footer {
    background-color: #001b44;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
      .icons {
        display: flex;
        gap: 16px;
        font-size: 1.2rem;
      }
    }
  }
}

// *************************** overrides ***************************
.text-primary {
  color: $clr-primary !important;
}

.news-page {
  // tabs
  .nav-tabs {
    display: flex;
    justify-content: center;

    .nav-link {
      color: $clr-silver !important;
      padding: 0;
      margin-right: 1.5rem;
      background-color: unset !important;
      box-shadow: none !important;
      border: none;

      &.active {
        color: black !important;
        background-color: unset;
        border: unset;
        font-weight: 600;
        border-bottom: 2px solid $clr-primary;
      }
      &:hover,
      &:focus {
        box-shadow: unset !important;
      }
    }
  }
}

// *************************** utils ***************************
.text-gradient {
  background: linear-gradient(90.22deg, #0063f7 -5.2%, #b0a9fc 117.97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.button-primary {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 24px;
  gap: 4px;
  background: linear-gradient(63.77deg, #0063f7 26.15%, #b0a9fc 92.34%);
  border-radius: 6px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
}

.text-silver {
  color: $clr-silver;
  text-decoration: none;
}

.text-neutral {
  color: #394452;
}
.feat {
  background: linear-gradient(90deg, #f805fd 0%, #18c8df 100%);
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  top: 0px;
  font-family: "roboto";
  font-style: normal;
  font-weight: 600;
}
.bar {
  height: 6px;
  width: 100%;
  background: linear-gradient(90.22deg, #0063f7 -5.2%, #b0a9fc 117.97%);
  border-radius: 100px;
}
.link-btn {
  all: unset;
  background: linear-gradient(90deg, #f805fd 0%, #18c8df 100%);

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.page-header {
  min-height: 250px;
  padding: 40px 10px;
  background: url("./media/newsbg.png") center no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.view-all-btn {
  all: unset;
  background-color: #ebeef2;
  padding: 14px 24px 14px 24px;
  border-radius: 6px;
  font-size: 14px;
  color: #394452;
  gap: 6px;
  @include center();
  cursor: pointer;
}

.form {
  width: 500px;
  margin: auto;
  p {
    font-family: "roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #6941c6;
  }
  h2 {
    font-family: "roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #101828;
  }
  h3 {
    font-family: "roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #667085;
  }
  input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    // margin-top:10px ;
    padding: 5px;
  }
  textarea {
    width: 100%;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }
  label {
    font-family: "roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-top: 15px;
  }
  button {
    background: linear-gradient(90.22deg, #0063f7 -5.2%, #b0a9fc 117.97%);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: "roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.008em;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
  }
}

.slider {
  img {
    height: 500px !important;
    object-fit: cover;
  }
}
.slider1 {
  background: url(./media/1.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
}

.articles {
  border-radius: 0.625rem !important;
  // box-shadow: 0 8px 40px -12px rgba(0,0,0,0.3);
  background: #eeedef;
  transition: 0.3s ease-in-out a {
    color: #3f51b5;
    font-size: 16px;
    font-weight: bold;
    font-family: "Roboto";
    // font-size: 1.5rem;
    text-decoration: none;
    line-height: 1.334;
    letter-spacing: 0em;
  }
  p {
    margin-top: 10px;
    font-family: "roboto";
    font-size: 14px;
    color: rgb(33, 37, 41);
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
  &:hover {
    box-shadow: 0 5px 30px -5.125px rgba(0, 0, 0, 0.3);
    transition: 0.5s;
  }
}
